import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import WhoWeAre from '../../components/careers/WhoWeAre'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

interface StateProps {
  LocationState: LocationData
}

interface LocationData {
  roleLocation: string
  link: string
}

const uXDesigner: React.FC<StateProps> = () => {
  const [locationData, setLocationData] = useState<LocationData>({
    roleLocation: 'London, UK',
    link: 'https://apply.workable.com/lensesio/j/2F8F6945BA/',
  })
  const { roleLocation, link } = locationData

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var location = url.searchParams.get('location')
    if (location == 'gr') {
      setLocationData({
        roleLocation: 'Athens, GR',
        link: 'https://apply.workable.com/lensesio/j/2A0F501265/',
      })
    }
  }, [])

  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a UX Designer in London and Athens. Apply to join our team!',
    },
    keywords: {
      keywords: 'UX Designer, London,Athens,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/6BOahas3kDHDCxzcf4tbYy/8f1d164a0202c56f9d0b33364a90110f/ux-designer.jpeg',
      },
    },
    title: {
      title: 'UX Designer',
    },
  }

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link rel="canonical" href="https://lenses.io/careers/ux-designer/" />
      </Helmet>
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">UX Designer</h1>
          <p className="meta">{roleLocation} </p>
          <section className="section section--text">
            <WhoWeAre />

            <h5 className="pt-3">What your day-to-day looks like:</h5>
            <p>
              As a UX Designer you are a hands-on problem solver, as well as a
              skilled and creative interaction designer, who specifies UX and UI
              requirements, designs and builds prototypes and pragmatic
              solutions. It will be your mission to deeply understand user
              problems and drive the creation of effective user-interfaces and
              experiences, and you will work closely with cross-functional
              teams, product managers and engineers.
            </p>
            <h5 className="pt-3">What you will bring:</h5>

            <ul className="pl-4 ">
              <li>
                A degree in Human-Computer-Interaction (HCI), Interaction
                Design, Visual Design or an equivalenteducation background or
                similar UI/UX position
              </li>
              <li>
                Proven experience in designing software products or complex
                enterprise applications
              </li>
              <li>
                Ability to use prototyping as a method to demonstrate your ideas
              </li>
              <li>
                Excitement working closely with engineering teams, product
                managers and stakeholders and takingcomplex data concepts to
                great experiences
              </li>
              <li>An understanding of the concepts of atomic designs</li>
              <li>
                Skill to deliver ready-for-development UI designs (using our
                design system)
              </li>
              <li>
                Experience in contributing to HTML/CSS/JS components/Storybook
                is a huge plus
              </li>
              <li>
                A good understanding in data visualization and information
                architecture
              </li>
              <li>Use of tools (or similar) like Sketch, Abstract, Zeplin</li>
              <li>Enjoyment working with remote teams</li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default uXDesigner
